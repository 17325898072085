<template>
  <div class="flex mb-40 health-stats">
    <div class="health-sector">
      <div class="health-sector-title">{{ sector_types[type] }} क्षेत्र</div>
      <img
        v-if="type == 'health'"
        src="../../assets/img_health.svg"
        alt=""
        width="48"
        height="64"
      />
      <img
        v-else-if="type == 'education'"
        src="../../assets/img_education.svg"
        alt=""
        width="48"
        height="64"
      />
      <img
        v-else-if="type == 'agriculture'"
        src="../../assets/img_agriculture.svg"
        alt=""
        width="48"
        height="64"
      />
      <img
        v-else
        src="../../assets/img_water_sanitation.svg"
        alt=""
        width="48"
        height="64"
      />
    </div>
    <div class="flex count-wrap">
      <div class="count">
        <span>{{ policyCount ? policyCount : "-" }}</span>
        नीतिहरु
      </div>
      <div class="count">
        <span>{{ decisionCount ? decisionCount : "-" }}</span>
        निर्णयहरु
      </div>
      <div class="count">
        <span v-if="totalBudget && totalBudget != 'undefined'"
          ><small>रु.</small> {{ totalBudget }}</span
        >
        <span v-else> - </span>
        कार्यक्षेत्रगत बजेट
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sector_types: {
        health: "स्वास्थ्य",
        education: "शिक्षा",
        agriculture: "कृषि",
        water_sanitation: "पानी र सरसफाइ",
      },
      type: this.$route.params.type,
    };
  },
  props: {
    decisionCount: { type: String, required: false },
    policyCount: { type: String, required: false },
    totalBudget: { type: String, required: false },
    sectorType: { type: String, required: false },
  },
  watch: {
    sectorType: {
      handler(val) {
        if (val) {
          this.type = val;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.title-filter {
  align-items: center;
  justify-content: space-between;
}

.health-stats {
  align-items: center;
  background: $neutrals-white;
  border-radius: 20px;
  @media all and (max-width: 768px) {
    display: block;
  }
}

.health-sector {
  background: $primary;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  text-align: center;
  padding: 40px;
  width: 200px;

  &-title {
    color: $neutrals-white;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  @media all and (max-width: 768px) {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    padding: 20px;
    width: 100%;
  }
}
</style>
